import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
// Define a functional component for the admin panel
let path = process.env.REACT_APP_PATH;
function AdminPanel() {
  // Declare state variables for users and food items
  const [users, setUsers] = useState([]);
  const [foodItems, setFoodItems] = useState([]);

  // Use the useEffect hook to fetch users and food items from an API
  useEffect(() => {
    // Fetch users from the API
    if (token == null) {
      Navigate("/login");
      return;
    }
    axios
      .get(path + "/getUsers", { headers: { Authorization: token } })
      .then((response) => {
        if (response.data.data == "sorry page not found") {
          Navigate("/");
          alert(response.data.data);
          return;
        }

        setUsers(response.data.data);
      });

    // Fetch food items from the API
    axios
      .get(path + "/getfoodItems", { headers: { Authorization: token } })
      .then((response) => {
        if (response.data.data == "sorry page not found") {
          Navigate("/");
          // return alert(response.data.data);
        }
        setFoodItems(response.data.data);
      });
  }, []);

  // Define a function to delete a user
  function deleteUser(userId, action) {
    // Send a DELETE request to the API to delete the user
    axios.delete(path + `/deleteUsers/${userId}?action=${action}`).then(() => {
      // Filter the users array to remove the deleted user
      const updatedUsers = users?.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
    });
  }

  // Define a function to delete a food item
  function deleteFoodItem(foodItemId) {
    // Send a DELETE request to the API to delete the food item
    axios.delete(path + `/deletefoodItems/${foodItemId}`).then(() => {
      // Filter the foodItems array to remove the deleted food item
      const updatedFoodItems = foodItems?.filter(
        (item) => item.id !== foodItemId
      );
      setFoodItems(updatedFoodItems);
    });
  }
  let token = localStorage.getItem("token");

  let Navigate = useNavigate();
  // Render the admin panel with a table of users and food items
  return (
    <div>
      {token == null ? (
        ""
      ) : (
        <>
          {console.log("users", users, "foodItems", foodItems)}

          <h1>Admin Panel</h1>
          <h2>Users</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.usersFullName}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>
                      {user.status == "disabled" ? (
                        <Button onClick={() => deleteUser(user.id, "active")}>
                          Enable
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => deleteUser(user.id, "disabled")}
                        >
                          Disable
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <h2>Food Items</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foodItems?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => deleteFoodItem(item.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default AdminPanel;
