import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import { ConsumeContext } from "./ContextProvider";
import { Alert, Button, Paper, Typography } from "@mui/material";
import axios from "axios";
import MenueSnack from "./MenueSnack";
import ProgressBar from "./ProgressBar";
let path = process.env.REACT_APP_PATH;
function OrderRecived() {
  const [isUpdateSuccessful, setisUpdateSuccessful] = useState(false);
  const [ShowProgressBar, setShowProgressBar] = useState(false);
  const [{ OrderRecivedItems, token }, SETORDER] = ConsumeContext();
  const [MyorderLength, setMyorderLength] = useState(0);
  useEffect(() => {
    let Counter = 0;
    OrderRecivedItems?.map((item) => {
      Counter += JSON.parse(item.orderContent).length;
    });
    setMyorderLength(Counter);
  }, [OrderRecivedItems]);
  let verifyCancellationByUser = (e, orderItems) => {
    orderItems.token = token;
    setShowProgressBar(true);
    axios
      .put(path + "/verifyCancellationByUser", { orderItems })
      .then((responce) => {
        console.log("responce", responce);
        if (responce.data == "updated") {
        }
        setTimeout(() => {
          setShowProgressBar(false);
        }, 2000);
      })
      .catch((error) => {
        setShowProgressBar(false);
        console.log(error);
      });
    //
  };

  return (
    <>
      <MenueSnack
        isUpdateSuccessful={isUpdateSuccessful}
        setisUpdateSuccessful={setisUpdateSuccessful}
      />

      {ShowProgressBar && <ProgressBar />}
      <center className="MyorderLength">
        <h3>You have recived {MyorderLength} items odrer</h3>
      </center>
      <br />
      {/* {console.log("menuItems", OrderRecivedItems)} */}
      {OrderRecivedItems?.map((item, index) => {
        // console.log(item.orderContent);

        return (
          <>
            <Paper elevation={3} sx={{ p: 2 }}>
              <br />
              <Menu
                tableNumber={item?.tableNumber}
                key={"menuItems_" + index}
                menuItems={JSON.parse(item?.orderContent)}
                SOURCE={"ORDERRECIVED"}
                ORDEREDITEMS={item}
              />
              {item?.orderStatus === "cancelled by user" && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Typography variant="subtitle1">
                    <Alert
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      severity="warning"
                      open={true}
                    >
                      Alert: This order has been cancelled by the user.
                    </Alert>
                  </Typography>
                  <Button
                    color="warning"
                    disabled={ShowProgressBar}
                    variant="contained"
                    style={{ marginTop: "10px" }}
                    onClick={(e) => verifyCancellationByUser(e, item)}
                  >
                    {ShowProgressBar ? "Please wait..." : "Verify Cancellation"}
                  </Button>
                </div>
              )}{" "}
              <br />
            </Paper>
            {/* {console.log("ORDEREDITEMS", OrderRecivedItems[0].orderStatus)} */}
            <br /> <br /> <br />
          </>
        );
      })}
    </>
  );
}
export default OrderRecived;
