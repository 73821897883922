import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const PasswordResetForm = () => {
  let path = process.env.REACT_APP_PATH;
  let Navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);

  const handleGenerateCode = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(path + "/generate-code", {
        phoneNumber,
      });
      const data = response.data;
      setMessage(data.message);
      setStep(2); // Proceed to step 2 (verify code)
    } catch (error) {
      console.error(error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(path + "/verify-code", {
        phoneNumber,
        resetCode,
      });
      const data = response.data;
      setMessage(data.message);
      setStep(3); // Proceed to step 3 (reset password)
    } catch (error) {
      console.error(error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(path + "/reset-password", {
        phoneNumber,
        newPassword,
      });
      const data = response.data;
      setMessage(data.message);
      Navigate("/");
    } catch (error) {
      console.error(error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  let formContent;
  if (step === 1) {
    // Step 1: Phone Number
    formContent = (
      <form onSubmit={handleGenerateCode}>
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button variant="contained" type="submit" sx={{ mt: 2 }}>
          Generate Reset Code
        </Button>
      </form>
    );
  } else if (step === 2) {
    // Step 2: Reset Code
    formContent = (
      <form onSubmit={handleVerifyCode}>
        <TextField
          label="Reset Code"
          variant="outlined"
          value={resetCode}
          onChange={(e) => setResetCode(e.target.value)}
        />
        <Button variant="contained" type="submit" sx={{ mt: 2 }}>
          Verify Reset Code
        </Button>
      </form>
    );
  } else if (step === 3) {
    // Step 3: New Password
    formContent = (
      <form onSubmit={handleResetPassword}>
        <TextField
          label="New Password"
          variant="outlined"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Button variant="contained" type="submit" sx={{ mt: 2 }}>
          Reset Password
        </Button>
      </form>
    );
  }

  return (
    <Box sx={{ maxWidth: 400, mx: "auto", mt: 4 }}>
      <Typography variant="h5" align="center" sx={{ mb: 3 }}>
        Reset Password
      </Typography>

      {formContent}

      {message && (
        <Typography
          variant="body1"
          color={message.includes("error") ? "error" : "success"}
          sx={{ mt: 2 }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default PasswordResetForm;
