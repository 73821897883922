import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import { ConsumeContext } from "./ContextProvider";
import { Alert, Button, Paper, Typography } from "@mui/material";
import ProgressBar from "./ProgressBar";
import axios from "axios";

let path = process.env.REACT_APP_PATH;
function OrderRequested() {
  const [{ OrderMenuItems, token }, SETORDER] = ConsumeContext();
  const [MyorderLength, setMyorderLength] = useState(0);
  useEffect(() => {
    let Counter = 0;
    OrderMenuItems?.map((item) => {
      // console.log("item is ===", JSON.parse(item.orderContent));
      Counter += JSON.parse(item.orderContent).length;
    });
    setMyorderLength(Counter);
  }, [OrderMenuItems]);
  let verifyCancellationByHotel = (e, item) => {
    console.log(e, item);
    setShowProgressBar(true);
    axios
      .put(path + "/verifyCancellationByHotel", { token, item })
      .then((data) => {
        console.log("@verifyCancellationByHotel", data.data.data);
        setTimeout(() => {
          setShowProgressBar(false);
        }, 2000);
      })
      .catch((error) => {
        setShowProgressBar(false);
        console.log(error);
      });
  };
  const [ShowProgressBar, setShowProgressBar] = useState(false);
  let verifyDeliveryofItemsByHotel = (e, item) => {
    // console.log(item);
    setShowProgressBar(true);
    axios
      .put(path + "/verifyDeliveryofItemsByHotel", { item, token })
      .then((responces) => {
        console.log(responces);
        setTimeout(() => {
          setShowProgressBar(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {ShowProgressBar && <ProgressBar />}
      <center className="MyorderLength">
        <h3>You have sent {MyorderLength} items order</h3>
      </center>
      <br />
      {OrderMenuItems?.map((item, index) => {
        return (
          <>
            <Paper elevation={3} style={{ padding: "10px" }}>
              <br />
              <Menu
                tableNumber={item?.tableNumber}
                key={"menuItems_" + index}
                menuItems={JSON.parse(item.orderContent)}
                SOURCE={"ORDER"}
                ORDEREDITEMS={OrderMenuItems[index]}
              />
              {item.orderStatus == "Delivered" && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Alert
                    open={true}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>Thank you. Your order is delivered to you.</div>
                  </Alert>
                  <Button
                    disabled={ShowProgressBar}
                    variant="contained"
                    color="warning"
                    style={{ marginTop: "10px" }}
                    onClick={(e) => verifyDeliveryofItemsByHotel(e, item)}
                  >
                    {ShowProgressBar ? "Please wait..." : "Verify delivery"}
                  </Button>
                </div>
              )}
              {item.orderStatus === "cancelled by hotel" && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Alert
                    severity="warning"
                    open={true}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      Alert: Sorry, this order has been cancelled by the
                      supplier.
                    </div>
                  </Alert>
                  <Button
                    disabled={ShowProgressBar}
                    variant="contained"
                    color="warning"
                    style={{ marginTop: "10px" }}
                    onClick={(e) => verifyCancellationByHotel(e, item)}
                  >
                    {ShowProgressBar ? "Please wait..." : "Verify Cancellation"}
                  </Button>
                </div>
              )}
              <br />
            </Paper>
            <br /> <br />
          </>
        );
      })}
    </>
  );
}
export default OrderRequested;
