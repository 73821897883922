import React, { useEffect, useState } from "react";
import { ConsumeContext } from "./ContextProvider";
import Box from "@mui/material/Box";
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
// import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
function EmployeesGet({ getMyEmployees }) {
  const [{ token, myEmployees }, SETORDER] = ConsumeContext();
  let removeMyemployee = (employee) => {
    console.log(employee);
    axios
      .delete(path + "/removeMyemployee", { data: { token, employee } })
      .then((data) => {
        console.log(data.data.data);
        getMyEmployees();
        let responce = data.data.data;
        if (responce == "deleted succesfully") {
          alert(responce);
        } else {
          alert(responce);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let path = process.env.REACT_APP_PATH;
  const [MyTableNumbers, setMyTableNumbers] = useState([]);
  let getmyTableNumbers = () => {
    let token = localStorage.getItem("token");
    console.log("token", token);
    axios
      .get(path + "/getTableNumbers", {
        headers: { Authorization: token },
      })
      .then((data) => {
        console.log("getTableNumbers", data.data.data);
        setMyTableNumbers(data.data.data);
      })
      .then((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getmyTableNumbers();
  }, []);
  const [SelectedEmployee, setSelectedEmployee] = useState({});
  let chooseTableNumbers = (Employee) => {
    setSelectedEmployee(Employee);
    setOpenModal(true);
  };
  let addTableNumbersToEmployee = () => {
    axios
      .put(path + "/addTableNumbersToEmployee", {
        selectedTableNumbers,
        SelectedEmployee,
      })
      .then((data) => {
        alert(data.data.data);
        getMyEmployees();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedTableNumbers, setSelectedTableNumbers] = useState([]);
  const handleCheckboxChange = (event, tableNumber) => {
    if (event.target.checked) {
      setSelectedTableNumbers([...selectedTableNumbers, tableNumber]);
    } else {
      setSelectedTableNumbers(
        selectedTableNumbers.filter((number) => number !== tableNumber)
      );
    }
  };
  const [openModal, setOpenModal] = useState(false);

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const contentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "4px",
    maxWidth: "400px",
    margin: "0 auto",
  };
  return (
    <Box>
      <Box overflow="auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone number</TableCell>
              <TableCell>Table numbers</TableCell>{" "}
              <TableCell>Assign table numbers</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myEmployees.length > 0 &&
              myEmployees?.map((employee) => (
                <TableRow key={employee?.id}>
                  {/* {console.log("employee", employee)} */}
                  <TableCell>{employee?.usersFullName}</TableCell>
                  <TableCell>
                    <Link
                      sx={{ textDecoration: "none" }}
                      href={`tel:${employee?.phoneNumber}`}
                    >
                      {employee?.phoneNumber}
                    </Link>
                  </TableCell>
                  <TableCell>{employee?.TableNumbers}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        chooseTableNumbers(employee);
                      }}
                    >
                      Choose table numbers
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={(e) => removeMyemployee(employee)}
                      variant="contained"
                      color="error"
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <Modal
        style={modalStyle}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div style={contentStyle}>
          <h2>my table number</h2>
          <Checkbox
            checked={selectedTableNumbers.includes("Others")}
            onChange={(event) => handleCheckboxChange(event, "Others")}
          />
          <span>Others</span>

          {MyTableNumbers.map((table) => (
            <div key={table.TableId}>
              <Checkbox
                checked={selectedTableNumbers.includes(table.tableNumber)}
                onChange={(event) =>
                  handleCheckboxChange(event, table.tableNumber)
                }
              />
              <span>{table.tableNumber}</span>
            </div>
          ))}
          <Button
            variant="contained"
            onClick={() => {
              setOpenModal(false);
              addTableNumbersToEmployee();
            }}
          >
            Add
          </Button>
        </div>
      </Modal>
    </Box>
  );
}
export default EmployeesGet;
