function MyDate(requets, DateValue) {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  // console.log(formattedDate); // Prints something like "2023-09-07 14:30:00"
  if (requets == "current") return formattedDate;
  if (requets == "ymd") return `${year}-${month}-${day}`;
  if (requets == "convert") {
    if (DateValue == null) return "Waitting....";
    const dateTime = new Date(DateValue);

    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formattedDateTime = dateTime.toLocaleString("en-US", options);
    // console.log(formattedDateTime); // Output: 23/09/11, 8:48:30 AM}
    return formattedDateTime;
  }
}
export default MyDate;
