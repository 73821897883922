import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import Slider from "react-slick";

function EachMenue({
  targetMenue,
  SOURCE,
  RemoveItemsFromBasket,
  handleOrder,
  handleOpen,
  setShowDeleteConfirMation,
  heading,
}) {
  let path = process.env.REACT_APP_PATH;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  let Navigate = useNavigate();
  return (
    <div>
      {targetMenue?.length > 0 && (
        <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h4">{heading}</Typography>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            {targetMenue.map((item, index) => (
              <Grid
                item
                key={"keyOfMenuItems_" + index + "_" + new Date()}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                {/* {console.log("item is ==", item)} */}
                <Card>
                  <Slider {...settings}>
                    <div key={"imgs_" + index}>
                      <CardMedia
                        component="img"
                        height={350}
                        image={
                          item.filesName === "" ||
                          item.filesName === "NoFile" ||
                          item.filesName === undefined ||
                          item.filesName === null
                            ? item.imageUrl
                            : path + "/uploads/" + item.filesName
                        }
                        alt={
                          item.filesName === "" || item.filesName === "NoFile"
                            ? item.imageUrl
                            : path + "/uploads/" + item.filesName
                        }
                      />
                    </div>
                  </Slider>

                  <CardContent>
                    <Typography variant="h6" component="div">
                      {item.ProductName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                    <Typography variant="subtitle1" color="text.primary">
                      Price: {item.price}
                    </Typography>
                    <h4
                      style={{
                        cursor: "pointer",
                        color: "blueviolet",
                        padding: "10px 1px",
                      }}
                      onClick={async () => {
                        Navigate("/Business/" + item.BusinessName);
                      }}
                    >
                      Supplied By {item.usersFullName}
                    </h4>
                    <br />
                    {SOURCE == "VIEW" ? (
                      <Button
                        style={{ zIndex: 1 }}
                        variant="contained"
                        onClick={() => RemoveItemsFromBasket(item, index)}
                        color="warning"
                      >
                        Remove
                      </Button>
                    ) : SOURCE == "ORDER" ? (
                      // <Button variant="contained" color="error">
                      //   Cancel this order
                      // </Button>
                      ""
                    ) : SOURCE == "Transaction" ? (
                      ""
                    ) : SOURCE == "ORDERRECIVED" ? (
                      ""
                    ) : (
                      <Box
                        sx={{
                          width: "250px",
                          display: "flex",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleOrder(item)}
                        >
                          Order
                        </Button>

                        {item.isOwner == "YES" && (
                          <>
                            <Button
                              variant="outlined"
                              onClick={() => handleOpen(item)}
                            >
                              Edit
                            </Button>
                            <Button
                              color="error"
                              onClick={(e) => {
                                // console.log(e);
                                setShowDeleteConfirMation({
                                  item: item,
                                  open: true,
                                });
                              }}
                              variant="contained"
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}

export default EachMenue;
