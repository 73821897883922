import React, { useEffect, useState } from "react";
import MyDate from "./MyDate";
import axios from "axios";
import { Box, FormLabel, TextField, Alert, Typography } from "@mui/material";
import Menu from "./Menu";
import ProgressBar from "./ProgressBar";
function History() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [Transaction, setTransaction] = useState({ status: "wait..." });
  useEffect(() => {
    setToDate(MyDate("ymd"));
    setFromDate(MyDate("ymd"));
    console.log(toDate, fromDate);
  }, []);
  useEffect(() => {
    if (toDate != null && fromDate != null) getMyTransaction();
  }, [toDate, fromDate]);

  let path = process.env.REACT_APP_PATH;
  let getMyTransaction = () => {
    let token = localStorage.getItem("token");
    setShowProgressBar(true);
    axios
      .get(
        path + "/getMyTransaction?fromDate=" + fromDate + "&toDate=" + toDate,
        {
          headers: { authorization: token },
        }
      )
      .then((responce) => {
        console.log(responce);
        setShowProgressBar(false);
        console.log("responce.data.data", responce.data.data);
        let result = responce.data.data;
        if (result == `unable to login`) {
          setTransaction((previousState) => {
            return { status: "To see your history you have to login first." };
          });
          // return alert("To see your history you have to login first.");
        } else if (responce.data.data == `no data found`) {
          setTransaction((previousState) => {
            return { status: "no data found  between the above dates" };
          });
        }
        // return alert(responce.data.data + " in these date ranges");
        else if (Array.isArray(responce.data.data)) {
          setTransaction((previousState) => {
            return { status: "Valid data", menu: responce.data.data };
          });
        }
      })
      .catch((error) => {
        setShowProgressBar(false);
        console.log(error);
      });
  };
  const [ShowProgressBar, setShowProgressBar] = useState(true);
  let token = localStorage.getItem("token");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {ShowProgressBar && <ProgressBar />}
      {token && (
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "5px",
              justifyContent: "center",
            }}
          >
            <FormLabel sx={{ textAlign: "center" }}>From Date</FormLabel>
            <TextField
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              value={fromDate}
              type="date"
              sx={{ maxWidth: "fit-content" }}
            />
          </Typography>
          {/* <input type="datetime-local"/> */}
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <FormLabel sx={{ textAlign: "center" }}>To Date</FormLabel>
            <TextField
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
              }}
              type="date"
              sx={{ maxWidth: "fit-content" }}
            />
          </Typography>
        </Box>
      )}
      {Transaction?.status == "Valid data" ? (
        Transaction?.menu?.map((item, index) => {
          return (
            <>
              <Box
                elevation={3}
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <br />
                <Menu
                  tableNumber={item?.tableNumber}
                  key={"menuItems_" + index}
                  menuItems={JSON.parse(item?.orderContent)}
                  SOURCE={"Transaction"}
                  ORDEREDITEMS={item}
                />
                {item?.orderStatus === "cancelled by user" && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Typography variant="subtitle1">
                      <Alert
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        severity="warning"
                        open={true}
                      >
                        Alert: This order has been cancelled by the user.
                      </Alert>
                    </Typography>
                  </div>
                )}{" "}
                <br />
              </Box>
              <br /> <br /> <br />
            </>
          );
        })
      ) : (
        <h4 style={{ textAlign: "center" }}>{Transaction?.status}</h4>
      )}
    </Box>
  );
}

export default History;
