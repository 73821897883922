import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Button, Modal, Box, TextField } from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LocalStorage from "./LocalStorage";
import { ConsumeContext } from "./ContextProvider";
import MenueSnack from "./MenueSnack";
import EachMenue from "./EachMenue";
import MyDate from "./MyDate";

const Menu = ({ menuItems, SOURCE, ORDEREDITEMS, tableNumber }) => {
  console.log("menuItems", menuItems);
  const [ShowProgressBar, setShowProgressBar] = useState(false);
  const [token, setToken] = useState("");
  const [ShowDeleteConfirMation, setShowDeleteConfirMation] = useState({
    item: {},
    open: false,
  });
  let getToken = async () => {
    let res = await LocalStorage("token", "", "get");
    setToken(res);
  };
  useEffect(() => {
    getToken();
  }, []);

  let [{ OrderBasket }, SETORDER] = ConsumeContext();
  const [showMenueItems, setshowMenueItems] = useState([]);
  const [DinnerMenu, setDinnerMenu] = useState([]);
  const [BreakfastMenu, setBreakfastMenu] = useState([]);
  const [LunchMenu, setLunchMenu] = useState([]);
  useEffect(() => {
    setshowMenueItems(menuItems);
  }, [menuItems]);
  const [TotalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let Counter = 0;
    let DinnerCopy = [],
      LunchCopy = [],
      breakFastCopy = [];
    let makeCopy = async () => {
      Counter = 0;
      showMenueItems.map((item, index) => {
        // get price of items
        Counter += parseFloat(item.price);
        if (item.PreparationTime == "dinner") {
          DinnerCopy.push(item);
        } else if (item.PreparationTime == "lunch") {
          LunchCopy.push(item);
        } else if (item.PreparationTime == "breakfast") {
          breakFastCopy.push(item);
        }
      });
      // console.log(Counter);
      setTotalPrice(Counter);
    };

    makeCopy();
    setBreakfastMenu([...breakFastCopy]);
    setLunchMenu([...LunchCopy]);
    setDinnerMenu([...DinnerCopy]);
  }, [showMenueItems]);

  let path = process.env.REACT_APP_PATH;
  const [open, setOpen] = useState(false);
  const [editedItem, setEditedItem] = useState(null);

  const handleOpen = (item) => {
    setEditedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      const updatedItem = {
        ...editedItem,
        token,
      };
      setShowProgressBar(true);
      // return;
      const response = await axios.put(path + "/updateMenueItems", updatedItem);
      // console.log("Update successful:", response.data);
      setShowProgressBar(false);
      let copy = showMenueItems.map((item) => {
        if (item.id == updatedItem.id) return updatedItem;
        return item;
      });
      setisUpdateSuccessful({ open: true, message: "Updated successfully" });
      setshowMenueItems(copy);
      setOpen(false);
      // Handle any further logic or UI updates after successful update
    } catch (error) {
      console.error("Error updating content:", error);
      // Handle error and show appropriate error message to the user
    }
  };

  const handleOrder = (item) => {
    console.log("item is ", item);
    let add = false;
    if (OrderBasket?.length == 0) add = true;
    else if (OrderBasket?.length >= 1) {
      if (OrderBasket[0].phoneNumber == item.phoneNumber) {
        add = true;
      }
    }
    if (add)
      SETORDER((prevstate) => {
        return { ...prevstate, OrderBasket: [...OrderBasket, item] };
      });
    else {
      alert("you can't add different suppliers product in one basket");
    }
    // Handle order request for the selected item
    // console.log("Order requested for:", item);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };
  const [isUpdateSuccessful, setisUpdateSuccessful] = useState({
    open: false,
    message: "",
  });

  let RemoveItemsFromBasket = (item, index) => {
    // console.log(item);
    let copy = [];
    OrderBasket.map((order, i) => {
      if (index == i) {
        return;
      }
      copy.push(order);
    });
    SETORDER((prevstate) => {
      return { ...prevstate, OrderBasket: copy };
    });
    setshowMenueItems(copy);
  };
  const [deliveryModal, setDeliveryModal] = useState({ open: false });
  let orderDeliveredToCustomer = async (orderReciveds) => {
    let copy = [];
    setShowProgressBar(true);
    console.log("orderReciveds===", orderReciveds);
    setDeliveryModal({ open: false });
    // return;
    ORDEREDITEMS.token = token;
    console.log("token", token);
    // return;
    let updateRequest = await axios
      .put(path + "/updateDeliverySuccess/", {
        ORDEREDITEMS: orderReciveds,
      })
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
    setShowProgressBar(false);
  };
  let deleteMenueItems = async (item) => {
    try {
      // console.log(item);
      let responce = await axios
        .post(path + "/deleteMenueItem", { item: { ...item, token } })
        .then(async (data) => {
          // console.log(data.data.data);
          let responce = data.data.data;

          setshowMenueItems(responce);
          // console.log("data is data===", data);
          setisUpdateSuccessful({
            open: true,
            message: "Deleted successfully",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  let cancelMyOrderRequest = (e, items) => {
    setShowProgressBar(true);
    setcancelOrderRequest({ open: false, item: {}, canceledBy: "" });
    let { canceledBy } = cancelOrderRequest;
    axios
      .post(path + "/cancelMyOrderRequest", {
        token,
        orderId: items.orderId,
        canceledBy,
        cancellationTime: MyDate("current"),
      })
      .then((responce) => {
        console.log("responce", responce);
        if (responce.data.data == "Please login first.") {
          return alert("Please login first.");
        }
        setShowProgressBar(false);
        setisUpdateSuccessful({
          open: true,
          message: "success",
        });
        // getOrderItems();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [cancelOrderRequest, setcancelOrderRequest] = useState({
    open: false,
    item: {},
    canceledBy: "",
  });
  return (
    <>
      {/* {console.log("tableNumber", tableNumber)} */}
      {tableNumber != undefined && tableNumber != null && tableNumber != "" && (
        <Box sx={{ textAlign: "center" }}>
          {ORDEREDITEMS != undefined &&
          ORDEREDITEMS?.outOfOursAddress != "" &&
          ORDEREDITEMS?.outOfOursAddress != null ? (
            <h2>
              {"Table number: " +
                tableNumber +
                ", " +
                `${JSON.parse(ORDEREDITEMS?.outOfOursAddress)?.phoneNumber}` +
                ", " +
                JSON.parse(ORDEREDITEMS?.outOfOursAddress)?.specificAddress}
            </h2>
          ) : (
            <>
              <h4> Table number : {tableNumber}</h4>
              <h4>order time {MyDate("convert", ORDEREDITEMS.orderedTime)}</h4>
              <h4>
                {/* {console.log("ORDEREDITEMS.orderStatus", ORDEREDITEMS)} */}

                {ORDEREDITEMS.orderStatus == "cancelled by user" ||
                ORDEREDITEMS.orderStatus == "cancelled by hotel" ? (
                  <>
                    {"Cancilation time : " +
                      MyDate("convert", ORDEREDITEMS?.cancellationTime)}
                  </>
                ) : (
                  "Delivery Time: " +
                  MyDate("convert", ORDEREDITEMS?.deliveryTime)
                )}
              </h4>
              {
                //ORDEREDITEMS.orderStatus == "cancelled by hotel" &&
                SOURCE == "Transaction" && (
                  <div>{"served by " + ORDEREDITEMS.usersFullName}</div>
                )
              }
            </>
          )}
        </Box>
      )}
      <br />
      {ShowProgressBar && <ProgressBar />}
      <>
        <EachMenue
          ORDEREDITEMS={ORDEREDITEMS}
          spacing={2}
          setShowDeleteConfirMation={setShowDeleteConfirMation}
          handleOpen={handleOpen}
          handleOrder={handleOrder}
          RemoveItemsFromBasket={RemoveItemsFromBasket}
          SOURCE={SOURCE}
          targetMenue={BreakfastMenu}
          heading={"Breakfast Menu"}
        />
        <EachMenue
          container
          spacing={2}
          setShowDeleteConfirMation={setShowDeleteConfirMation}
          handleOpen={handleOpen}
          handleOrder={handleOrder}
          RemoveItemsFromBasket={RemoveItemsFromBasket}
          SOURCE={SOURCE}
          targetMenue={LunchMenu}
          heading={"Lunch Menu"}
        />
        <EachMenue
          container
          spacing={2}
          setShowDeleteConfirMation={setShowDeleteConfirMation}
          handleOpen={handleOpen}
          handleOrder={handleOrder}
          RemoveItemsFromBasket={RemoveItemsFromBasket}
          SOURCE={SOURCE}
          targetMenue={DinnerMenu}
          heading={"Dinner Menu"}
        />
        <br />
        {(SOURCE == "ORDERRECIVED" ||
          SOURCE == "ORDER" ||
          SOURCE == "VIEW" ||
          SOURCE == "Transaction") && (
          <center style={{ textDecoration: "underline", paddingBottom: "5px" }}>
            <h3>Total price of items : {TotalPrice}</h3>
          </center>
        )}
        <Modal
          open={deliveryModal.open}
          onClose={() => setDeliveryModal({ open: false, item: "" })}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Are you sure you have delivered this item?
            </Typography>
            {!ShowProgressBar ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let deliveryTime = MyDate("current");
                  deliveryModal.item.deliveryTime = deliveryTime;
                  orderDeliveredToCustomer(deliveryModal.item);
                }}
              >
                Yes
              </Button>
            ) : (
              <Button variant="contained" disabled>
                Processing ...
              </Button>
            )}
            &nbsp; &nbsp; &nbsp;
            <Button
              onClick={() => setDeliveryModal({ open: false })}
              variant="contained"
              color="warning"
            >
              No
            </Button>
          </Box>
        </Modal>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Edit Content
            </Typography>
            <TextField
              name="ProductName"
              onChange={(e) => handleInputChange(e)}
              label="Product name "
              value={editedItem?.ProductName}
              fullWidth
              margin="normal"
            />
            <TextField
              name="imageUrl"
              onChange={(e) => handleInputChange(e)}
              label="Image url"
              value={editedItem?.imageUrl}
              fullWidth
              margin="normal"
            />
            <TextField
              name="description"
              onChange={(e) => handleInputChange(e)}
              label="Description"
              value={editedItem?.description}
              fullWidth
              margin="normal"
            />
            <TextField
              name="price"
              onChange={(e) => handleInputChange(e)}
              label="Products price"
              value={editedItem?.price}
              fullWidth
              margin="normal"
            />
            {!ShowProgressBar ? (
              <Button variant="contained" color="primary" onClick={handleSave}>
                Update
              </Button>
            ) : (
              <Button variant="contained" disabled>
                Processing ...
              </Button>
            )}
          </Box>
        </Modal>
      </>
      {SOURCE == "ORDER" &&
        ORDEREDITEMS.orderStatus !== "cancelled by hotel" &&
        ORDEREDITEMS.orderStatus !== "Delivered" && (
          <center>
            <br />
            <br />

            <Button
              sx={{ margin: "auto" }}
              onClick={(e) => {
                setcancelOrderRequest({
                  open: true,
                  item: ORDEREDITEMS,
                  canceledBy: "cancelled by user",
                });
              }}
              variant="contained"
              color="error"
            >
              {/* cancelMyOrderRequest(e, ORDEREDITEMS); */}
              Cancel this order
            </Button>
          </center>
        )}
      {/* {console.log(" ORDEREDITEMS.orderStatus", ORDEREDITEMS?.orderStatus)} */}
      {SOURCE == "ORDERRECIVED" &&
        ORDEREDITEMS.orderStatus !== "cancelled by user" && (
          <center>
            <br />
            <Button
              onClick={(e) =>
                setDeliveryModal({ open: true, item: ORDEREDITEMS })
              }
              variant="contained"
            >
              Delivered
            </Button>
            &nbsp; &nbsp;
            <Button
              onClick={(e) => {
                setcancelOrderRequest({
                  open: true,
                  item: ORDEREDITEMS,
                  canceledBy: "cancelled by hotel",
                });
              }}
              variant="contained"
              color="error"
            >
              Cancel this order
            </Button>
            <br />
            <br />
            <br />
          </center>
        )}
      <MenueSnack
        isUpdateSuccessful={isUpdateSuccessful}
        setisUpdateSuccessful={setisUpdateSuccessful}
      />
      {/* Confirmation dialog to cancel requested orders */}
      {cancelOrderRequest.open && (
        // cancilation modal will be here
        <Modal
          open={cancelOrderRequest.open}
          onClose={() =>
            setcancelOrderRequest({
              open: false,
              item: {},
              canceledBy: "",
            })
          }
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6">Confirm</Typography>
            <Typography variant="body1">
              Are you sure you want to cancel this request?
            </Typography>
            {/* <Typography variant="body1">
              Item: {cancelOrderRequest.item.orderId}
            </Typography> */}
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              onClick={(e) => {
                cancelMyOrderRequest(e, cancelOrderRequest.item);
              }}
            >
              Verify
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() =>
                setcancelOrderRequest({ open: false, item: {}, canceledBy: "" })
              }
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
      {/* deleteMenueItems(item); */}
      {ShowDeleteConfirMation.open && (
        <Dialog
          open={ShowDeleteConfirMation.open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                setShowDeleteConfirMation({ open: false, item: "" })
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowDeleteConfirMation({ open: false, item: "" });
                deleteMenueItems(ShowDeleteConfirMation.item);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default Menu;
