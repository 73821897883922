import { Button, Link, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { ConsumeContext } from "./ContextProvider";
function Employeeseregister({ getMyEmployees }) {
  const [{ token, myEmployees }, SETORDER] = ConsumeContext();
  const [searchInputValue, setsearchInputValue] = useState("");
  const [SearchResults, setSearchResults] = useState({
    status: "",
    employeeList: [],
  });
  let Navigate = useNavigate();
  let path = process.env.REACT_APP_PATH;
  let submitEmployeeSearch = (e) => {
    if (e == "notFromEvent") {
    } else e.preventDefault();
    console.log("path", path, searchInputValue);
    axios
      .get(path + "/searchEmployees?NameOrPhone=" + searchInputValue, {
        headers: { Authorization: token },
      })
      .then((responce) => {
        let results = responce.data.data;
        console.log("@submitEmployeeSearch results", results);
        if (results == "loginFirst")
          setSearchResults((prevState) => {
            return {
              ...prevState,
              employeeList: [],
              status: (
                <h4
                  style={{ cursor: "pointer", color: "blueviolet" }}
                  onClick={() => {
                    Navigate("/login");
                  }}
                >
                  Login first
                </h4>
              ),
            };
          });
        else if (Array.isArray(results)) {
          if (results.length == 0) {
            setSearchResults((prevState) => {
              return {
                ...prevState,
                employeeList: [],
                status: (
                  <h4 style={{ cursor: "pointer", color: "blueviolet" }}>
                    No data found
                  </h4>
                ),
              };
            });
          } else {
            let Employees = responce.data.myEmployees;
            let copy = [];
            SETORDER((prev) => {
              return { ...prev, myEmployees: Employees };
            });
            results.map((Value) => {
              console.log("Value", Value, "MyEmployees", myEmployees);
              Employees.map((item) => {
                console.log("Value", Value, "item", item);
                if (item.employeeId == Value.id) {
                  Value.isEmplyee = true;
                }
              });
            });
            // try to match it to verify r they my employees or ?
            //////
            setSearchResults((prevState) => {
              return {
                ...prevState,
                status: "",
                employeeList: results,
              };
            });
          }
        }
        console.log("responce", results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    SETORDER((prev) => {
      return { ...prev, token: localStorage.getItem("token") };
    });
  }, []);
  let AddEmployee = (e, employee) => {
    axios
      .put(path + "/addEmployees", { token, employee })
      .then((responce) => {
        submitEmployeeSearch("notFromEvent");
        let myResponce = responce.data.data;
        console.log("myResponce", myResponce);
        if (myResponce == "already employee") alert(myResponce);
        else if (myResponce == "EmployeeAdded")
          alert("Employee added successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let removeMyemployee = (employee) => {
    console.log(employee);
    axios
      .delete(path + "/removeMyemployee", { data: { token, employee } })
      .then((data) => {
        console.log(data.data.data);
        let responce = data.data.data;
        if (responce == "deleted succesfully") {
          alert(responce);
        } else {
          alert(responce);
        }
        submitEmployeeSearch("notFromEvent");
        // getMyEmployees("callToNext", submitEmployeeSearch);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "400px",
          padding: "20px",
        }}
        onSubmit={submitEmployeeSearch}
      >
        <br />
        <TextField
          label="Name or phone number"
          values={searchInputValue}
          onChange={(e) => {
            setsearchInputValue(e.target.value);
          }}
        />
        <br />
        <Button type="submit" variant="contained">
          Submit
        </Button>
        <div>{SearchResults.status}</div>
      </form>
      <Table overflow="auto">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {SearchResults?.employeeList &&
            SearchResults?.employeeList?.map((employee) => (
              <TableRow key={employee?.id}>
                <TableCell>{employee?.usersFullName}</TableCell>
                <TableCell>
                  <Link
                    sx={{ textDecoration: "none" }}
                    href={`tel:${employee?.phoneNumber}`}
                  >
                    {employee?.phoneNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  {!employee?.isEmplyee ? (
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        AddEmployee(e, employee);
                      }}
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => removeMyemployee(employee)}
                      variant="contained"
                      color="error"
                    >
                      Remove
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* {SearchResults?.employeeList?.map((employee) => {
        console.log("employee", employee);
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeList &&
                employeeList.map((employee) => (
                  <TableRow key={employee.id}>
                    <TableCell>{employee.usersFullName}</TableCell>
                    <TableCell>{employee.phoneNumber}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        );
        // BusinessName: "Marew123";
        // id: 1;
        // password: "";
        // passwordResetPin: "775122";
        // phoneNumber: "+251922112480";
        // status: "active";
        // usersFullName: "Marew123";
      })} */}
    </div>
  );
}

export default Employeeseregister;
