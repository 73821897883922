import React, { useEffect, useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import Employeeseregister from "./Employeeseregister";
import axios from "axios";
import { ConsumeContext } from "./ContextProvider";
import EmployeesGet from "./EmployeesGet";

function MyTabs() {
  const [value, setValue] = useState(0);
  const [{ token, myEmployees }, SETORDER] = ConsumeContext();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let path = process.env.REACT_APP_PATH;
  // const [MyEmployees, setMyEmployees] = useState([]);
  let getMyEmployees = () => {
    axios
      .get(path + "/getMyEmployees", { headers: { Authorization: token } })
      .then((responces) => {
        console.log("@getMyEmployees responces", responces.data.data);
        // if (callToNext == "callToNext") callBack("notFromEvent");
        SETORDER((prev) => {
          return { ...prev, myEmployees: responces.data.data };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (token) getMyEmployees();
  }, [token]);
  useEffect(() => {
    console.log("myEmployees", myEmployees);
  }, [myEmployees]);

  useEffect(() => {
    SETORDER((prev) => {
      return { ...prev, token: localStorage.getItem("token") };
    });
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <center>
        <h3>Employees</h3>
      </center>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="View" />
        <Tab label="Add" />
        {/* <Tab label="Tab 3" /> */}
      </Tabs>

      {value === 0 && (
        <Typography>
          <EmployeesGet getMyEmployees={getMyEmployees} />
        </Typography>
      )}
      {value === 1 && (
        <Typography>
          <Employeeseregister getMyEmployees={getMyEmployees} />
        </Typography>
      )}
      {value === 2 && <Typography>Content for Tab 3</Typography>}
    </div>
  );
}

export default MyTabs;
